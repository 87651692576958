import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// import BMap from 'BMap';

import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import message from 'antd/es/message';

import Header from '../components/header';
import Footer from '../components/footer';
import Icon from '../components/icon';
import Content from '../components/content';
import SEO from '../components/seo';

import { sendEmail } from '../api/server';

import contractStyles from '../styles/contract.module.less';

export default () => {
    const [loading, setLoading] = useState(false);
    const [loadMap, setLoadMap] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "bg/contract_us.jpg" }) {
                childImageSharp {
                    fluid(maxHeight: 557) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    const contractList = [
        {
            logo: 'iconqunfengdianhua',
            title: '联系电话',
            content: '400-000-2900'
        },
        {
            logo: 'iconqunfengyouxiang',
            title: '联系邮箱',
            content: 'marketing@kuguanwang.com'
        },
        {
            logo: 'iconqunfengxiangxidizhi',
            title: '联系地址',
            content: '上海市普陀区常德路1339号'
        }
    ];
    const handleApply = async (values) => {
        // console.log(values);
        setLoading(true);
        const queryParams = {
            clientName: values.userCompany,
            fullName: values.userName,
            mobile: values.userPhone,
            to: 'wangyang@guojing.net',
            productName: '店货通',
            email: values.userEmail,
            position: '',
            size: '',
            content: values.messageContent
        };
        const res = await sendEmail(queryParams);
        if (res) {
            message.info('提交成功');
        }
        setLoading(false);
    };
    useEffect(() => {
        // console.log(Reflect.has(window, 'BMap'));
        // console.log(window);
        if (Reflect.has(window, 'BMap')) {
            const BMap = window.BMap;
            const BMAP_ANCHOR_TOP_LEFT = window.BMAP_ANCHOR_TOP_LEFT;
            const BMAP_NORMAL_MAP = window.BMAP_NORMAL_MAP;
            const BMAP_HYBRID_MAP = window.BMAP_HYBRID_MAP;
            const map = new BMap.Map('allmap');
            map.centerAndZoom(new BMap.Point(121.441283, 31.248082), 17);
            const topLeftControl = new BMap.ScaleControl({ anchor: BMAP_ANCHOR_TOP_LEFT }); // 左上角，添加比例尺
            const topLeftNavigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件
            map.setCurrentCity('上海');
            map.addControl(
                new BMap.MapTypeControl({
                    mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP]
                })
            );
            map.addControl(topLeftControl);
            map.addControl(topLeftNavigation);
            map.enableScrollWheelZoom(true); // 允许拖动缩放
            const marker = new BMap.Marker(new BMap.Point(121.441283, 31.248082));
            map.addOverlay(marker); // 添加打点坐标
            const dialogOpt = {
                width: 200,
                height: 80,
                title: '上海市慧仓信息技术有限公司',
                message: ''
            };
            const infoWindows = new BMap.InfoWindow(
                '地址：上海市普陀区常德路1339号金昌商务中心A座401',
                dialogOpt
            );
            map.openInfoWindow(infoWindows, new BMap.Point(121.441283, 31.248082));
            marker.addEventListener('click', function() {
                map.openInfoWindow(infoWindows, new BMap.Point(121.441283, 31.248082));
            });
        }
    }, [loadMap]);

    setTimeout(() => {
        setLoadMap(true);
    }, 1000);

    return (
        <div>
            <Helmet>
                <script
                    type="text/javascript"
                    src="https://api.map.baidu.com/api?v=2.0&ak=G2scKFqqX8LMANGMD2GEijTl4RnFkUcQ&services=true&s=1"
                ></script>
                <script
                    type="text/javascript"
                    src="https://api.map.baidu.com/getscript?v=2.0&ak=G2scKFqqX8LMANGMD2GEijTl4RnFkUcQ&services=true&t=20200327103013"
                ></script>
            </Helmet>
            <SEO title="联系我们" />
            <Header />
            <div className={contractStyles.contractHeader}>
                <Img fluid={data.bannerImage.childImageSharp.fluid} />
                <div className={contractStyles.contractHeader__content}>
                    <h2>联系我们</h2>
                </div>
            </div>
            <Content
                className={contractStyles.contractMethod}
                title="联系我们"
                description="如有任何问题请联系我们，24小时竭诚为您服务"
                titleStyle={{ fontSize: '30px' }}
                descriptionStyle={{ fontSize: '20px' }}
            >
                <ul className={contractStyles.contractMethod__list}>
                    {contractList.map((contract, index) => (
                        <li className={contractStyles.contractMethod__listItem} key={index}>
                            {/* <i
                                className={`iconfont ${contractStyles.contractMethod__listItem__logo}`}
                                dangerouslySetInnerHTML={{ __html: contract.logo }}
                            ></i> */}
                            <Icon
                                type={contract.logo}
                                className={contractStyles.contractMethod__listItem__logo}
                            />
                            <p className={contractStyles.contractMethod__listItem__title}>
                                {contract.title}
                            </p>
                            <p className={contractStyles.contractMethod__listItem__content}>
                                {contract.content}
                            </p>
                        </li>
                    ))}
                </ul>
            </Content>
            <div style={{ backgroundColor: 'rgba(132, 132, 132, 0.3)' }}>
                <Content className={contractStyles.contractAddress}>
                    <div className={contractStyles.contractAddress__content}>
                        <h2>联系我们</h2>
                        <p>电话：400-000-2900</p>
                        <p>邮箱：marketing@kuguanwang.com</p>
                        <p>网址：http://www.dianerp.com</p>
                        <p>地址: 上海市普陀区常德路1339号</p>
                    </div>
                    <div id="allmap" className={contractStyles.contractAddress__map}></div>
                </Content>
            </div>
            <Content
                className={contractStyles.contractApply}
                title="发表您的留言"
                titleStyle={{ fontSize: '30px' }}
                descriptionStyle={{ fontSize: '20px' }}
            >
                <Form
                    className={contractStyles.contractApply__form}
                    onFinish={handleApply}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ offset: 1, span: 12 }}
                >
                    <Form.Item
                        label="您的姓名"
                        name="userName"
                        rules={[{ required: true, message: '请填写您的姓名' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="您的电话"
                        name="userPhone"
                        rules={[
                            { required: true, message: '请填写您的电话' },
                            { pattern: /^1(3|4|5|6|7|8)\d{9}$/, message: '请填写合法的手机号' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="您的邮箱" name="userEmail">
                        <Input />
                    </Form.Item>
                    <Form.Item label="您的公司" name="userCompany">
                        <Input />
                    </Form.Item>
                    <Form.Item label="留言内容" name="messageContent">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={contractStyles.contractApply__form__button}
                        loading={loading}
                    >
                        提交留言
                    </Button>
                </Form>
            </Content>

            <Footer />
        </div>
    );
};
